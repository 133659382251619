@import './componentsStyling/theme.scss';
$randomNumber: random(4);
@font-face {
    font-family: 'Karla';
    src: url('../src/fonts/Karla/Karla-Regular.ttf');
}

@font-face {
    font-family: 'Manrope';
    src: url('../src/fonts/Manrope-Regular.ttf');
}

#root {
    height: 100vh;
    overflow: hidden;
    .theme {
        display: inline-block;
        padding: 7vh;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    & .theme--dark {
        background-image: url('./photos/backgrounds/Fundal 4 – 1.png');
    }
    & .theme--classic {
        background-image: url('./photos/backgrounds/Fundal 4 – 1.png');
    }
    .built-in {
        color: #fff;
        float: right;
        padding-right: 45px;
        padding-top: 15px;
        a {
            color: #fff;
            &:hover {
                color: #fff;
            }
        }
    }
}

.app-container {
    height: 100%;
    border-radius: 100px;
    box-shadow: 0px 3px 25px #0f0b1382;
    .chain-switcher,
    .dashboard-btn {
        font-family: "Karla";
        padding: 5px 25px;
        border-radius: 25px;
        background-color: #030328;
        color: #F2E9DC;
        border: 1px solid #19D2D2;
        img{
            width: 100%;
        }
    }
    .dashboard-btn {
        width: 15%;
        padding: 5px 15px;
        border: 1px solid #D742F3;
    }
    .main-row,
    .side-menu-container {
        height: 100%;
    }
    .language-switcher {
        font-family: "Karla";
        padding: 5px 25px;
        border-radius: 25px;
        background-color: #030328;
        color: #F2E9DC;
        border: 1px solid #19D2D2;
        margin: 0 5px;
        text-align: center;
        option {
            font-size: 12px;
        }
    }
    @include themed() {
        color: t('text');
        background: t('bg');
        header {
            margin: 80px;
            width: 75%;
            height: 80px;
            border-top-right-radius: 100px;
            background: t('bg');
            position: absolute;
            justify-content: end;
        }
        li svg {
            color: t('text');
        }
        li button.MuiPaginationItem-page {
            background-color: t('text');
            color: t('pagination')
        }
        .main-container {
            margin-top: 0;
            display: flex;
            overflow-y: auto;
            .floating-btn {
                position: fixed;
                bottom: 75px;
                right: 15px;
            }
            .pagination {
                justify-content: space-between;
            }
            .content-box {
                // background-color: t('logo-bg');
                margin: 15px 15px 25px 15px;
                padding: 0 15px;
                // box-shadow: t('box-shadow');
                // border-radius: 25px 10px 85px 25px;
                overflow: hidden;
                width: 100%;
                &.full-height {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .home-page-box {
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                color: t('text');
                font-size: 26px;
            }
            h3 {
                color: t('text');
                font-size: 22px;
            }
        }
        .message-container,
        .d-none {
            display: none;
        }
        .maintenance-mode {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            .maintenance-img {
                max-height: 250px;
                width: auto;
            }
            h1 {
                color: t('text');
            }
            h4 {
                font-weight: normal;
                margin-top: 30px;
                font-size: 20px;
                color: t('text');
            }
        }
        .editor span {
            color: t('text') !important;
        }
        .navigation-mobile,
        .connect-mobile {
            display: none;
        }
    }
    .initial-page {
        height: 100%;
        position: relative;
        .language-switcher {
            position: absolute;
            right: 75px;
            top: 20px;
        }
        .row {
            height: 100%;
            overflow: hidden;
            .img-container {
                text-align: center;
                height: 100%;
                position: relative;
                .image--left {
                    left: 13%;
                    width: 752px;
                    height: 652px;
                    position: absolute;
                    top: 79px;
                }
                .img-content {
                    position: absolute;
                    color: #fff;
                    font-size: 2rem;
                    font-weight: 400;
                    top: 155px;
                    left: 28%;
                    width: 550px;
                    p {
                        text-align: center;
                        font-size: 27px;
                        margin-bottom: 0;
                    }
                    .content-logo {
                        display: inline;
                        height: 35px;
                        margin: -15px 0 0 0;
                    }
                    .connect-button {
                        background-color: #ECFCFC;
                        color: #2B2235;
                        border: 1px solid #19D2D2;
                        border-radius: 33px;
                        box-shadow: none;
                        margin-top: 50px;
                        padding: 10px 25px;
                        width: 235px;
                        height: 50px;
                        font-weight: 800;
                    }
                }
            }
            .text-container {
                @include themed() {
                    text-align: center;
                    img {
                        margin: 35vh 0 50px;
                        width: 55%;
                    }
                    * {
                        color: t('text');
                        font-size: 30px;
                        font-weight: bold;
                        line-height: 35px;
                    }
                    .text-green {
                        margin-top: 10px;
                        color: #91C499;
                        display: inline-block;
                    }
                }
            }
        }
        .show-popup {
            font-size: 27px;
            margin-top: -5px;
            margin-left: 5px;
            font-weight: bold;
            padding: 0;
            color: #78C3FB;
            text-decoration: underline;
            background: transparent;
        }
        @media (max-width: 768px) {
            // .main-container {
            //     overflow-y: inherit;
            // }
        }
    }
    .initial-page.contracts {
        .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .image--left {
                position: relative;
                top: unset;
                left: unset;
            }
            p {
                font-size: 60px;
            }
        }
    }
    .MuiTablePagination-toolbar {
        gap: 10px;
        .MuiTablePagination-spacer {
            display: none;
        }
        .MuiTablePagination-selectLabel,
        .MuiTablePagination-displayedRows {
            margin: 0;
            color: #fff;
        }
        .MuiTablePagination-select {
            padding: 2px;
            border: 1px solid #62CFD0;
            border-radius: 50px;
            background-color: transparent;
            color: #fff;
        }
        .MuiTablePagination-menuItem {
            color: #F2E9DC;
            background: #030328;
        }
        .MuiTablePagination-actions {
            padding: 2px;
            border: 1px solid #62CFD0;
            border-radius: 50px;
            text-align: center;
        }
        .MuiTablePagination-actions>button {
            margin: 0 8px;
            border: transparent;
            border-radius: 4px;
            background-color: transparent;
            color: #fff;
            &:disabled {
                opacity: 0.3;
            }
        }
    }
}

.chain-popper,
.language-popper {
    z-index: 1203;
    width: 250px;
    padding: 15px;
    margin-top: 15px !important;
    border-radius: 25px;
    background-color: #051645;
    box-shadow: 0px 3px 15px #0f0b1373;
    button {
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: 'Karla';
        &:hover {
            color: #fff;
        }
        img {
            max-width: 25px;
            margin-right: 10px;
        }
    }
    .pulse,
    .optimism,
    .ethpow,
    .okx,
    .dc,
    .base {
        border-radius: 50%;
    }
    .img-container.eth {
        width: 25px;
        margin-right: 10px;
        .eth {
            max-height: 25px;
            margin-right: 0;
        }
    }
}

.language-popper {
    width: 150px;
    button {
        justify-content: center;
        border-bottom: 1px solid #19D2D2;
        border-radius: 0%;
        &:last-child {
            border-bottom: 0;
        }
    }
}

.modal-box {
    height: 470px;
    width: 100%;
    max-width: 750px;
    margin-left: 50%;
    transform: translate(-50%, 40%);
    .close-popup {
        text-decoration: none;
    }
    &.dark {
        background: #2B2235 0% 0% no-repeat padding-box;
        border-radius: 100px;
        box-shadow: 0px 3px 25px #0F0B1380;
        border: 1px solid #2B2235;
        .close-popup {
            background: #2B2235;
            color: #fff;
            border: 1px solid #fff;
        }
        .add-contact-text {
            color: #F2E9DC;
        }
        .buttons-container {
            .cancel-button {
                color: #F5B700;
                &:hover {
                    background-color: #1F1826;
                }
            }
            .add-button {
                color: #F5B700;
                &:hover {
                    background-color: #1F1826;
                }
            }
        }
        .for-label {
            color: #F2E9DC
        }
        .inputs {
            color: #F2E9DC;
            background: #1F1826 0% 0% no-repeat padding-box;
            border: #1F1826;
            &:focus {
                background: #1F1826 0% 0% no-repeat padding-box;
                border: #1F1826;
            }
        }
        h3,
        h4 {
            color: #fff;
        }
    }
    &.classic {
        border: 1px solid #FFEEE8;
        border-radius: 100px;
        background-color: #FFEEE8;
        box-shadow: 0px 3px 25px #0F0B1380;
        position: relative;
        .add-contact-text {
            color: #2B2235;
        }
        .cancel-button:hover {
            background-color: #F5B700;
        }
        .for-label {
            color: #2B2235;
        }
        .inputs {
            background: #F2E2DC 0% 0% no-repeat padding-box;
            &:focus {
                background: #F2E2DC 0% 0% no-repeat padding-box;
            }
        }
    }
    .add-contact-text {
        text-align: center;
        margin-top: 50px;
        font-size: 26px;
    }
    .for-field {
        margin: 25px 50px;
        position: relative;
    }
    .for-label {
        margin: 0 0 10px 20px;
    }
    .buttons-container {
        display: flex;
        justify-content: space-between;
        padding: 0 50px;
        .cancel-button {
            width: 150px;
            border: 2px solid #F5B700;
            transition: 0.5s;
            border-radius: 33px;
            padding: 10px auto;
            text-transform: uppercase;
            &:hover {
                transition: 0.5s;
                border-radius: 33px;
            }
        }
        .add-button {
            width: 150px;
            background-color: #F5B700;
            border-radius: 33px;
            padding: 10px auto;
            transition: 0.5s;
            color: #2B2235 !important;
            text-transform: uppercase;
            &:hover {
                background: #F5B700;
                transition: 0.5s;
            }
        }
    }
    .inputs:focus {
        border-radius: 32px;
        height: 50px;
    }
    .inputs {
        border-radius: 32px;
        height: 50px;
    }
}

.how-to-use-list {
    padding: 30px 5%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    .close-popup {
        position: absolute;
        top: -20px;
        right: -20px;
        background-color: #FFEEE8;
        border-radius: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #333;
        text-align: center;
    }
    ul {
        list-style-type: none;
        padding: 0;
        flex-direction: row;
        display: flex;
        li {
            display: flex;
            flex-direction: column;
            width: 33%;
            padding: 0 1%;
            p {
                color: #280E44;
                font-size: 24px;
                font-weight: bold;
            }
            span {
                color: #CE4760;
            }
            img {
                max-width: 45px;
                margin-bottom: 15px;
            }
        }
        a {
            text-decoration: none;
        }
    }
    h3 img {
        max-height: 30px;
        vertical-align: baseline;
    }
    h4 {
        font-size: 20px;
    }
    h4 img {
        max-height: 30px;
        vertical-align: baseline;
    }
}

.initial-page-popup {
    .modal-box {
        width: 90%;
        .how-to-use-list {
            align-items: center;
            justify-content: center;
            ul {
                margin: 50px 0;
                a {
                    color: #809BCE;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #root {
        height: 100vh;
        .built-in {
            padding-right: 0;
            &.connected {
                position: absolute;
                top: 0;
                padding-top: 0;
            }
        }
        .theme {
            height: auto;
            min-height: 100vh;
            padding: 25px 10px;
            .app-container {
                height: 87vh;
                border-radius: 25px;
                header {
                    background-color: transparent;
                    margin: 60px 55px 30px;
                }
                .side-menu-container {
                    flex: auto;
                    height: auto;
                    display: none;
                }
                .main-container {
                    margin-top: 0;
                    overflow-y: inherit;
                    height: 55vh;
                    .content-box {
                        height: auto;
                        border-radius: 25px;
                        margin: 0;
                        padding-bottom: 35px;
                    }
                    ul.MuiList-root {
                        height: 63vh;
                        li.messages-list-item {
                            div.list-item-button {
                                .message-overflow {
                                    height: 25px;
                                }
                                &.active {
                                    .message-overflow {
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
                .navigation-mobile {
                    display: flex;
                    position: fixed;
                    left: 0;
                    width: 100%;
                    bottom: 0;
                    justify-content: space-around;
                    height: 8vh;
                    font-size: 1.5vh;
                    background-color: #051645;
                    z-index: 999;
                    .navigation-item {
                        width: 100%;
                        text-align: center;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 2px solid #030328;
                        &.active {
                            color: #19D2D2;
                        }
                        &.dashboard {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    div:last-child {
                        border-right: none;
                    }
                }
                .initial-page {
                    .img-container {
                        display: none;
                    }
                    .text-container {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .dark-logo {
                            margin: 15px;
                        }
                    }
                    .connect-mobile {
                        display: block;
                        .connect-button {
                            background-color: #ECFCFC;
                            color: #2B2235;
                            border: 1px solid #19D2D2;
                            border-radius: 33px;
                            box-shadow: none;
                            margin-top: 50px;
                            padding: 10px 25px;
                            width: 235px;
                            height: 50px;
                            font-weight: 400;
                            font-size: 2.5vh;
                            .unsupported {
                                font-size: 12px;
                                color: #2B2235;
                                line-height: 1;
                            }
                        }
                    }
                    .language-switcher {
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 2vh;
                        width: 25%;
                        padding: 5px;
                    }
                }
                .initial-page.contracts {
                    .img-container {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        .image--left {
                            position: relative;
                            left: unset;
                            top: unset;
                            max-width: 752px;
                            max-height: 652px;
                            width: 100%;
                            height: auto;
                        }
                    }
                    .img-content {
                        left: 50%;
                        transform: translate(-50%, -40%);
                        top: 40%;
                    }
                }
            }
        }
    }
    .initial-page-popup {
        .modal-box {
            overflow-y: scroll;
            padding: 50px 0 !important;
            .how-to-use-list {
                align-items: flex-start !important;
                justify-content: flex-start !important;
            }
            ul {
                flex-direction: column;
                li {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
            h4 {
                padding-bottom: 50px;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(204, 204, 204);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
    border-radius: 10px;
}

@media only screen and (min-width: 900px) {
    .theme {
        zoom: .8;
    }
    .cards-grid {
        height: 75vh;
    }
}

@media only screen and (min-width: 1050px) {
    .theme {
        zoom: .9;
    }
}

@media only screen and (min-width: 1200px) {
    .theme {
        zoom: 1;
    }
}